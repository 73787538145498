import React, { useEffect, useState } from "react";
import Logo from "../../assets/mlogo@2x.svg";
import "../../styles/Navbar.css";

function NavbarContent(props) {
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed-top ${hasShadow ? "with-shadow" : "nav-gradient"}`}>
      <div className={`nav-main-container`}>
        <a className="navbar-brand navbrand" href="https://fexo.io">
          <img src={Logo} alt="Navbar Logo" className="w-36" />
        </a>
        <div className="nav-content space-x-8">{props.children}</div>
      </div>
    </nav>
  );
}
export default NavbarContent;
