import React from "react";
import { PopupButton } from "react-calendly";

function BookCallBtn({ className }) {
  return (
    <PopupButton
      // url="https://calendly.com/sriramjeyabharathi/xt-invoice-automation"
      url="https://calendly.com/kaustubhkashyap/xt-invoice-automation-platform"
      rootElement={document.getElementById("root")}
      className={`${className} bg-orange-500 rounded-lg shadow-xl text-white text-lg`}
      text={<span>Book a call</span>}
    />
  );
}

export default BookCallBtn;
