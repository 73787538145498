import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import "../styles/Footer.css";
import axios from "axios";
import getURL from "../routes/api-urls";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    window.location.href =
      "mailto:info@openturf.in?subject=Hello%20Subject&body=Hello%20Body";
  };

  const [email, setEmail] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [privacyPopup, setPrivacyPopup] = useState(false);
  const [tosPopup, setTOSPopup] = useState(false);

  const subscribeToNewsLetter = () => {
    const data = {
      email: email,
    };

    const isValidEmail = (email) => {
      const emailRegex = // eslint-disable-next-line
        /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/i;
      return emailRegex.test(email);
    };

    if (!email) {
      setIsErrorModalOpen(true);
      setErrorMessage("Subscription failed. Please try again later.");
      return;
    }

    if (isValidEmail(email)) {
      axios
        .post(getURL("subscripe"), data)
        .then((response) => {
          console.log("Subscription successful:", response.data);
          setIsSuccessModalOpen(true);
          setEmail("");
        })
        .catch((error) => {
          console.error("Subscription failed:", error);
          setIsErrorModalOpen(true);
          setErrorMessage("Subscription failed. Please try again later.");
        });
    } else {
      setIsErrorModalOpen(true);
      setErrorMessage("Not a valid email. Please try again.");
      return;
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <div className="text-white">
      <PrivacyPolicy
        open={privacyPopup}
        handleClose={() => setPrivacyPopup(false)}
      />
      <TermsOfService open={tosPopup} handleClose={() => setTOSPopup(false)} />
      <div className="grid md:grid-cols-2 gap-10">
        <div className="order-2 md:order-1">
          <h3 className="text-4xl font-semibold mb-5">Contact us</h3>
          <div className="flex">
            <div className="custom-icon-container">
              <FaLocationDot />
            </div>
            <div className="col-md-11">
              <p className="footer-addresses">
                UAE Office: G131C, Technohub 1,
                <br />
                Dubai Technology Entrepreneur Campus (Dtec),
                <br />
                Dubai Silicon Oasis, Dubai, UAE
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div className="custom-icon-container">
              <FaLocationDot />
            </div>
            <div className="col-md-11">
              <p className="footer-addresses">
                India Office: 10th floor, RMZ Latitude Commercial Building,
                Bellary Rd, Hebbal, Bengaluru, Karnataka 560024, INDIA
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div className="custom-icon-container">
              <FaLocationDot />
            </div>
            <div className="col-md-11">
              <p className="footer-addresses">
                Grafenaustrasse 11 6300 Zug, Switzerland
              </p>
            </div>
          </div>
          <div className="ml-8 mt-5 flex justify-start items-center space-x-4">
            <div className="email-button" onClick={handleClick}>
              <AiOutlineMail size={30} />
            </div>
            <a
              href="https://www.linkedin.com/company/openturftechnologies/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
              className="socials"
            >
              <FaLinkedin size={30} />
            </a>
            <a
              href="https://twitter.com/OpenTurfTech"
              target="_blank"
              rel="noopener noreferrer"
              className="socials"
            >
              <FaTwitterSquare size={30} />
            </a>
          </div>
        </div>
        <div className="order-1 md:order-2">
          <h2 className="text-4xl font-semibold">Stay in touch</h2>
          <div className="mt-5 md:mt-16">
            <div>
              <div className="opacity-60 text-white text-base font-normal leading-relaxed tracking-wide">
                Subscribe to Nibbles. We'll be pleased to keep you up to date
                about modern technology, events, and innovative apps.
              </div>

              <input
                className="w-full border border-transparent border-b-2 border-b-gray-400 py-4 mt-5 focus:border-b-2 bg-transparent outline-none"
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="flex mt-2 justify-between flex-col md:flex-row items-center md:items-end gap-6 md:gap-0">
                <button
                  className="mt-4 w-52 h-14 bg-orange-500 rounded-lg focus:outline-none focus:bg-primary-bg-color hover:bg-white hover:text-black transition duration-300 transform"
                  onClick={subscribeToNewsLetter}
                >
                  Subscribe
                </button>
                <div className="flex gap-4">
                  <p
                    className="poplink footer-addresses"
                    onClick={() => navigate("/terms-of-service")}
                  >
                    Terms of Service
                  </p>
                  <p
                    className="poplink footer-addresses underline"
                    onClick={() => navigate("/privacy-policy")}
                  >
                    Privacy Policy
                  </p>
                </div>
              </div>
            </div>
          </div>

          {isSuccessModalOpen && (
            <div className="success-modal">
              <p>Subscription Successful!</p>
              <button onClick={closeSuccessModal} className="close-modal">
                Close
              </button>
            </div>
          )}
          {isErrorModalOpen && (
            <div className="error-modal">
              <p>{errorMessage}</p>
              <button onClick={closeErrorModal} className="close-modal">
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
