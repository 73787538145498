import React from "react";

const ShowReview = ({ clientsReview }) => {
  return (
    <div className="flex flex-col gap-6 justify-between px-6 py-4 rounded-lg w-full shadow-xl bg-white">
      <p className="text-base font-normal text-justify">
        {clientsReview.comments}
      </p>
      <div className="flex justify-center items-center gap-4">
        <img
          src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
          alt="profile"
          className="w-12 h-12 rounded-full"
        />
        <div className="flex flex-col">
          <h3 className="text-lg font-semibold">{clientsReview.name}</h3>
          <p className="text-[#2E0040] text-base font-normal">
            {clientsReview.position}
          </p>
        </div>
      </div>
    </div>
  );
};

function HappyClients() {
  const clientsReviews = [
    {
      id: 1,
      name: "CA Mohan",
      position: "Thangavel CA, Tirupur",
      comments:
        "FEXO has been a game-changer for our company. What used to take us 15 days to complete, we can now finish in just an hour. The accuracy and speed that FEXO brings to Bank Statement processing have significantly boosted our productivity. We can now focus on more strategic tasks rather than being bogged down by manual data entries. FEXO is a must-have for any book-keeping company looking to grow their business.",
    },
    {
      id: 2,
      name: "CA Sohil Ganeriwala",
      position: "Ganeriwala & Co, Bengaluru",
      comments:
        "FEXO's Bulk Upload feature has been incredibly helpful for our team. Managing large volumes of Invoices and Bank Statements used to be a daunting task, but with FEXO, we can upload everything in bulk without any hassle. It saves us a tremendous amount of time and ensures that our data is processed accurately. The best part is that we do not have to share this data outside our organization to digitize it. FEXO has truly transformed the way we handle data entries in Tally.",
    },
  ];
  return (
    <div>
      <h2 className="text-[#2E0040] sub-heading mb-8">Happy Customers</h2>
      <div className="flex flex-col px-8 md:flex-row md:px-4 gap-10">
        {clientsReviews.map((clientsReview) => (
          <ShowReview key={clientsReview.id} clientsReview={clientsReview} />
        ))}
      </div>
    </div>
  );
}

export default HappyClients;
